import React from 'react'
import { Link, withPrefix } from 'gatsby'
import { employes } from 'images'
import { window } from 'browser-monads'
import PricingColumn from 'components/pricing-column'
import CtaRow from 'components/cta-row'
import Helmet from 'react-helmet'
import Layout from '../../layouts'
import { FaStar, FaEuroSign } from 'react-icons/fa'
import Stars from 'components/stars'
import {
  direct,
  hand,
  kosten,
  vragen,
  steps,
  one,
  two,
  three,
  four,
  screenshot,
  confetti,
} from 'images/product/salaris'

import config from '../../config'
const { appUrl, signupRoute } = config

const comparisonData = [
  {
    label: 'Doelgroep',
    label_class: 'badge aqua',
    title_column_1: 'Alle soorten bedrijven',
    text_column_1:
      'Een salarisadministrateur kan eigenlijk alle soorten bedrijven verlonen.',
    title_column_2: 'Midden en grootbedrijf en salarisprofessionals',
    text_column_2:
      'Nmbrs is over het algemeen zeer uitgebreid en geschikt voor mensen met vergevorderde salariskennis.',
    title_column_3: 'MKB & DGA`s',
    text_column_3:
      'Bij Employes heb je geen salariskennis nodig waardoor het zeer geschikt is voor midden- en kleinbedrijf.',
  },

  {
    label: 'Gemak',
    label_class: 'badge orange',
    title_column_1: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_1:
      'Een salarisadministrateur neemt veel werk voor je uit handen. Maar werken met derden vereist natuurlijk wel extra communicatie.',
    title_column_2: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="uncheckedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_2:
      'Bestaande pakketten zijn echt bedoeld voor salarisexperts en zitten door de hoeveelheid aan functies dus niet altijd even logisch in elkaar. Weet jij bijvoorbeeld wat je Whk-premie is?',
    title_column_3: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="checkedStar" />,
    ],
    text_column_3:
      'Gemak, dat is waar het bij Employes om draait. Zonder salariskennis kun je eenvoudig medewerkers verlonen. De gemiddelde tijd voor de salarisverwerking is minder dan 6 minuten.',
  },
  {
    label: 'Support',
    label_class: 'badge aqua',
    title_column_1: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="checkedStar" />,
    ],
    text_column_1:
      'Hét voordeel van uitbesteden is dat je op alle salarisvragen en aanverwante vragen een antwoord kunt verwachten. ',
    title_column_2: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="uncheckedStar" />,
      <Stars key="3" checked="uncheckedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_2:
      '"Komt u er niet uit? Richt u zich dan op uw accountant of salarisspecialist", dat is wat de meest klantvriendelijke salarispakketten schrijven op hun site. Zelfredzaamheid of alsnog uitbesteden is dus een vereiste.',
    title_column_3: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_3:
      'Onze klantenservice bestaat uit salarisexperts die je kunnen helpen bij vragen op het gebied van salarisverwerking. Daarnaast helpen we je de eerste keer bij het instellen van je salarisinstelling.',
  },
  {
    label: 'Functies',
    label_class: 'badge blue',
    title_column_1: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="uncheckedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_1:
      'De dienstverlening is per kantoor verschillend. Tegen betaling willen accountants en boekhouder vaak veel voor je doen. Maar functies als online verlof aanvragen zul je hier niet vinden.',
    title_column_2: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="checkedStar" />,
    ],
    text_column_2:
      'De meest bekende softwarepakketten bieden een zeer uitgebreide lijst aan features. Verzin het, en het zit er in. Heb je een organisatie met managementlagen en zoek je bijvoorbeeld veel HRM-functies? Kies dan één van de grote bestaande pakketten.',
    title_column_3: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_3:
      'Naast alle denkbare functies voor salarisadministratie kun je met Employes bijvoorbeeld ook contracten maken, verlof aanvragen, documenten delen, een dossier bijhouden. Alle functies van belang voor een MKB bedrijf zitten erin.',
  },
  {
    label: 'Foutgevoelig',
    label_class: 'badge blue',
    title_column_1: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="uncheckedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_1:
      'Door de extra communicatie is er meer kansen op fouten. Tegelijkertijd heb je wel een expert die met je meekijkt.',
    title_column_2: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="uncheckedStar" />,
      <Stars key="3" checked="uncheckedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_2:
      'Doordat je zelf veel salarisinstellingen moet invoeren, is de kans op fouten groter. Je bent hierbij echt op jezelf aangewezen.',
    title_column_3: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_3:
      'Je wordt als het ware door de software heen geloodst waardoor de kans op fouten nihil is. En er is een salarisexpert die op de achtergrond meekijkt.',
  },
  {
    label: 'Snelheid',
    label_class: 'badge orange',
    title_column_1: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="uncheckedStar" />,
      <Stars key="3" checked="uncheckedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_1:
      'Het nadeel van uitbesteden is dat dat je afhankelijk bent van derden. Boekhouder op vakantie? Pech gehad.',
    title_column_2: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="uncheckedStar" />,
      <Stars key="5" checked="uncheckedStar" />,
    ],
    text_column_2:
      'De salarisverwerking in "eigen handen" zorgt voor optimale controle en snelheid. Als je eenmaal het pakket begrijpt dan kun je redelijk efficiënt je salarisverwerking doen.',
    title_column_3: [
      <Stars key="1" checked="checkedStar" />,
      <Stars key="2" checked="checkedStar" />,
      <Stars key="3" checked="checkedStar" />,
      <Stars key="4" checked="checkedStar" />,
      <Stars key="5" checked="checkedStar" />,
    ],
    text_column_3:
      'Met Employes kun je snel de salarisverwerking doen. De gemiddelde tijd om een verloning te doen, is minder 6 minuten.',
  },
  {
    label: 'Tarieven',
    label_class: 'badge orange',
    title_column_1: '€ 10 - € 20 per loonstrook',
    text_column_1:
      'Voor alle zaken naast de reguliere dienstverlening dient betaald te worden. Denk hierbij aan de kosten bij de invoer van een werknemer of een jaaropgave',
    title_column_2: 'Uiteenlopende tarieven',
    text_column_2:
      'Afhankelijk van de bedrijfsgrootte, verschillen de tarieven. Als klein bedrijf met 5 werknemers betaal je ongeveer € 10,- per loonstrook. De opstartkosten zijn €1.490,-',
    title_column_3: '€ 5 per loonstrook',
    text_column_3:
      'De kosten voor salarisverwerking zijn € 25,- per maand plus € 5,- per loonstrook. Er zijn geen opstartkosten.<br><a href="/tarieven/bedrijf-met-personeel/" className="link inline"> Bekijk de tarieven.</a>',
  },
]

class VergelijkenPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: '',
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id,
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="vergelijken salaris animated fadeInPage">
          <Helmet>
            <title>
              Redenen waarom bedrijven voor Employes kiezen in plaats van Nmbrs
            </title>
            <meta
              name="description"
              content="Het is tijd voor gebruiksvriendelijke software. Geen gedoe meer met salarisadministratie en HR. Employes is eenvoudig in gebruik voor iedereen binnen je bedrijf."
            />
            <meta
              itemprop="name"
              content="Redenen waarom bedrijven voor Employes kiezen in plaats van Nmbrs"
            />
            <meta
              itemprop="description"
              content="Het is tijd voor gebruiksvriendelijke software. Geen gedoe meer met salarisadministratie en HR. Employes is eenvoudig in gebruik voor iedereen binnen je bedrijf."
            />
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-xxl animated fadeInPage">
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-12">
                  <p className="eyebrow">Vergelijking Nmbrs vs. Employes</p>
                  {/*<h1>Enkele redenen waarom bedrijven voor Employes kiezen in plaats van Nmbrs</h1>*/}
                  <h1>Op zoek naar een alternatief voor Nmbrs?</h1>
                  <p
                    className="streamer large center margin-l-bottom"
                    style={{ maxWidth: 540 }}
                  >
                    Het is tijd voor gebruiksvriendelijke software. Geen gedoe
                    meer met salarisadministratie en HR. Employes is eenvoudig
                    in gebruik voor iedereen binnen je bedrijf.
                  </p>
                  <a
                    className="btn primary lg margin-l-bottom"
                    href={appUrl + signupRoute}
                    rel="nofollow"
                  >
                    Probeer nu gratis
                  </a>
                </div>

                <div className="col-12 no-pad relative">
                  <div className="screenshot">
                    {/*<img src={screenshot} alt="Illustration" ref="screenshot" />*/}

                    <video loop autoPlay muted playsInline ref="videoContainer">
                      <source
                        src={withPrefix('/employes-explain.mp4')}
                        type="video/mp4"
                      />
                    </video>
                  </div>

                  <div className="confetti">
                    <img
                      src={confetti}
                      alt="The confetti with different faces"
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="container-md margin-m-top">
            <div className="grid center text-center yg">
              <div className="col-8 section-header margin-xl-bottom">
                <p className="eyebrow">Gebruiksgemak en in eigen hand</p>
                <h2>Wat maakt Employes anders?</h2>
                <p>
                  Salarisadministratie is ingewikkeld, maar de hulpmiddelen
                  waarmee werknemers worden beheerd hoeven dat beslist niet te
                  zijn. Employes is intuïtief en speciaal gemaakt voor
                  ondernemers. Het doen van een verloning kan binnen een paar
                  minuten, zonder heen en weer te hoeven mailen met je
                  boekhouder.
                </p>
              </div>
            </div>

            <div className="backdrop-competitor"></div>

            <div className="rows table-column">
              <div className="grid yg row sticky-top table-heading">
                <div className="col-3"></div>
                <div className="col-3">
                  <img src={employes} alt="Employes logo" />
                </div>
                <div className="col-3">
                  <h4>Nmbrs</h4>
                </div>
              </div>

              {comparisonData.map((data, idx) => (
                <div key={idx} className="grid yg row">
                  <div className="col-3">
                    <span className={data.label_class}>
                      <b>{data.label}</b>
                    </span>
                  </div>
                  <div className="col-3">
                    <h5>{data.title_column_3}</h5>
                    <p
                      dangerouslySetInnerHTML={{ __html: data.text_column_3 }}
                    ></p>
                  </div>
                  <div className="col-3">
                    <h5>{data.title_column_2}</h5>
                    <p
                      dangerouslySetInnerHTML={{ __html: data.text_column_2 }}
                    ></p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <section className="vision padding-l-top padding-xl-bottom">
            <div className="container-sm">
              <div className="grid yg">
                <div className="col-12">
                  <p className="eyebrow">Conclusie</p>
                  <h3>De beste keuze voor je bedrijf</h3>
                </div>
                <div className="col-6">
                  <p className="margin-s-bottom">
                    Welke manier van salarisadministratie het beste bij je past,
                    is sterk afhankelijk van je wensen en de bedrijfsgrootte. We
                    kunnen niet zomaar stellen dat Employes of Nmbrs de beste of
                    slechtste is. Maar de verschillen zijn wel groot. Als je
                    geen salarisexpert binnen je bedrijf hebt (zoals een
                    HR-manager), dan is Nmbrs minder geschikt voor je. Wat dan
                    overblijft is de keuze tussen salarisadministratie doen met
                    Employes of het uitbesteden bij een plaatselijke accountant
                    of een administratiekantoor.
                    <br />
                    <br /> Salarisadministratie wordt vaak gezien als
                    ingewikkeld en tijdrovend. Toch hoeft dit tegenwoordig echt
                    niet meer zo te zijn.
                  </p>
                </div>
                <div className="col-6">
                  <p className="margin-s-bottom">
                    Met Employes wordt al bijna alles automatisch voor je
                    gedaan. Hierdoor heb je als ondernemer geen
                    salarisadminstrateur meer nodig en hoef je niet telkens heen
                    en weer te mailen met mutaties. <br />
                    <br /> Heb je een maand geen wijzigingen in je
                    salarisadministratie? Dan kun je kunt zelfs met één druk op
                    de knop je salarisadministratie doen. Eén ding is zeker,
                    salarisadministratie gebeurt voortaan automatisch.
                  </p>
                  <p className="seo-link">
                    Tags:&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-uitbesteden/"
                    >
                      Salarisadministratie uitbesteden
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarispakket/">
                      Salarispakket
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salaris-software/">
                      Salaris-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/personeelsadministratie/">
                      Personeelsadministratie
                    </Link>
                    ,&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-zelf-doen/"
                    >
                      Salarisadministratie-zelf-doen
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisverwerking/">
                      Salarisverwerking
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loon-software/">
                      Loon-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonadministratie/">
                      Loonadministratie
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonstrook-maken/">
                      Loonstrook maken
                    </Link>
                    ,&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratiekantoor/"
                    >
                      Salarisadministratiekantoor
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonadministrateur/">
                      Loonadministrateur
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/verloning/">
                      Verloning
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisprogramma/">
                      Salarisprogramma
                    </Link>
                    ,&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default VergelijkenPage
